const av = Autodesk.Viewing;
const GlobalManagerMixin = av.GlobalManagerMixin;

export function ExplodeTool(viewer) {
    av.ToolInterface.call(this);

    this.names = ['explode'];
    this.viewer = viewer;
    this.setGlobalManager(this.viewer.globalManager);
    this.active = false;

    this.activate = function (name, viewer) {
        this.active = true;
    };

    this.deactivate = function () {
        this.setScale(0);
        this.active = false;
    };

    this.setScale = function (value) {
        return this.viewer.explode(value);
    };

    this.isActive = function() {
        return this.active;
    }
}

GlobalManagerMixin.call(ExplodeTool.prototype);
